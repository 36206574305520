// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfigurationForm-module__elviyTXCPa7vL9GrDFNt {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./containers/PluginConfigPage/parts/ConfigurationForm/ConfigurationForm.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".info-block {\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-block": "ConfigurationForm-module__elviyTXCPa7vL9GrDFNt"
};
export default ___CSS_LOADER_EXPORT___;
