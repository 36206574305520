// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlackSettings-module__Pmz1QGYiP5Q5ZNfN31hw {\n  margin-bottom: 20px;\n}\n\n.SlackSettings-module__vj065UBuwqhtMH5opzWA {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.SlackSettings-module__rKLW8bpJFPsLTNfdpmZC .SlackSettings-module__lKipPoLSmX31bB6VpEAT {\n  width: 400px;\n}\n\n.SlackSettings-module__cCt0VoZWRwF74OKovfsm {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n\n.SlackSettings-module__f_uOt74uF0CnL3RfkeHa {\n  text-align: center;\n  width: 725px;\n}\n\n.SlackSettings-module__J1hVZ9flt3Br99gqEmn2 {\n  margin-right: 4px;\n  align-self: baseline;\n}\n\n.SlackSettings-module__thRoviIwGnhBbYczeGKi {\n  height: 30px;\n}\n\n.SlackSettings-module__kFQZTlApZnWItzIqehyQ {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.SlackSettings-module__W2uFQT5ThFO_lfh8nzk5 {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./pages/settings/tabs/ChatOps/tabs/SlackSettings/SlackSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.stub {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.root .select {\n  width: 400px;\n}\n\n.slack-settings {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n\n.slack-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.external-link-style {\n  margin-right: 4px;\n  align-self: baseline;\n}\n\n.team_workspace {\n  height: 30px;\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.infoblock-icon {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "SlackSettings-module__Pmz1QGYiP5Q5ZNfN31hw",
	"stub": "SlackSettings-module__vj065UBuwqhtMH5opzWA",
	"root": "SlackSettings-module__rKLW8bpJFPsLTNfdpmZC",
	"select": "SlackSettings-module__lKipPoLSmX31bB6VpEAT",
	"slack-settings": "SlackSettings-module__cCt0VoZWRwF74OKovfsm",
	"slack-infoblock": "SlackSettings-module__f_uOt74uF0CnL3RfkeHa",
	"external-link-style": "SlackSettings-module__J1hVZ9flt3Br99gqEmn2",
	"team_workspace": "SlackSettings-module__thRoviIwGnhBbYczeGKi",
	"infoblock-text": "SlackSettings-module__kFQZTlApZnWItzIqehyQ",
	"infoblock-icon": "SlackSettings-module__W2uFQT5ThFO_lfh8nzk5"
};
export default ___CSS_LOADER_EXPORT___;
