// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewScheduleSelector-module__ZHXjOgGPLmd3fq0XYKGv {\n  display: block;\n}\n\n.NewScheduleSelector-module__e663WQdqFGOPRTr6oSGh {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./components/NewScheduleSelector/NewScheduleSelector.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  display: block;\n}\n\n.block {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NewScheduleSelector-module__ZHXjOgGPLmd3fq0XYKGv",
	"block": "NewScheduleSelector-module__e663WQdqFGOPRTr6oSGh"
};
export default ___CSS_LOADER_EXPORT___;
