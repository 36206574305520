// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationBlockItem-module__tUDIqN8bRcBziWUcd6K3{display:flex;flex-direction:row;margin-bottom:4px;max-width:100%}.IntegrationBlockItem-module__TJYV6144kmE9a8ob378W{width:100%;padding-top:12px;padding-bottom:12px}", "",{"version":3,"sources":["webpack://./components/Integrations/IntegrationBlockItem.module.scss"],"names":[],"mappings":"AAAA,mDACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CAEA,mDACE,UAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".blockItem {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 4px;\n  max-width: 100%;\n\n  &__content {\n    width: 100%;\n    padding-top: 12px;\n    padding-bottom: 12px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockItem": "IntegrationBlockItem-module__tUDIqN8bRcBziWUcd6K3",
	"blockItem__content": "IntegrationBlockItem-module__TJYV6144kmE9a8ob378W"
};
export default ___CSS_LOADER_EXPORT___;
