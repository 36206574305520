// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WithPermissionControlTooltip-module__E_z498LINAkk1y5hAyLr {\n  display: inline;\n}\n", "",{"version":3,"sources":["webpack://./containers/WithPermissionControl/WithPermissionControlTooltip.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "WithPermissionControlTooltip-module__E_z498LINAkk1y5hAyLr"
};
export default ___CSS_LOADER_EXPORT___;
