// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tag-module__PAGiYEwOJpPvvROOBR5w {\n  border-radius: 2px;\n  line-height: 100%;\n  padding: 5px 8px;\n  color: white;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./components/Tag/Tag.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".root {\n  border-radius: 2px;\n  line-height: 100%;\n  padding: 5px 8px;\n  color: white;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Tag-module__PAGiYEwOJpPvvROOBR5w"
};
export default ___CSS_LOADER_EXPORT___;
