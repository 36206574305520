// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsPage-module__YpgOqMqMV6yjPxkAMEYh {\n  padding-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./pages/settings/SettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".tabs__content {\n  padding-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs__content": "SettingsPage-module__YpgOqMqMV6yjPxkAMEYh"
};
export default ___CSS_LOADER_EXPORT___;
