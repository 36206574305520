// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeRange-module__N1IT5W2lWCsjZdgO39kL {\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./components/TimeRange/TimeRange.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".root {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TimeRange-module__N1IT5W2lWCsjZdgO39kL"
};
export default ___CSS_LOADER_EXPORT___;
