// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Timeline-module__zr0YAnDcMieAdXW52CF1 {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.Timeline-module__NL8gqEb6VwBlD3KDTAZU {\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.Timeline-module__vHp6xHmxz5yke5XtzUOR {\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  text-align: center;\n  line-height: 28px;\n  font-size: 14px;\n  font-weight: 400;\n  color: white;\n  flex-shrink: 0;\n}\n\n.Timeline-module__eD0sArU4bkTjw5Fkj3aH {\n  margin: 0 0 0 24px;\n  word-break: break-word;\n  flex-grow: 1;\n}\n\n.Timeline-module__znrDf1kT8MTspIOCxr2o {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./components/Timeline/Timeline.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,SAAS;AACX","sourcesContent":[".root {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.dot {\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  text-align: center;\n  line-height: 28px;\n  font-size: 14px;\n  font-weight: 400;\n  color: white;\n  flex-shrink: 0;\n}\n\n.content {\n  margin: 0 0 0 24px;\n  word-break: break-word;\n  flex-grow: 1;\n}\n\n.content--noMargin {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Timeline-module__zr0YAnDcMieAdXW52CF1",
	"item": "Timeline-module__NL8gqEb6VwBlD3KDTAZU",
	"dot": "Timeline-module__vHp6xHmxz5yke5XtzUOR",
	"content": "Timeline-module__eD0sArU4bkTjw5Fkj3aH",
	"content--noMargin": "Timeline-module__znrDf1kT8MTspIOCxr2o"
};
export default ___CSS_LOADER_EXPORT___;
