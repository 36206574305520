// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoteFilters-module__Goj6Fnossnkos9z2aUTg {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.RemoteFilters-module__lfUhdeqSgBLwAacjmOZV {\n  display: flex;\n  gap: 10px;\n  padding: 10px;\n  border: var(--border);\n  border-radius: 2px;\n  flex-wrap: wrap;\n}\n\n.RemoteFilters-module__t7puoZgp9lSZbC4TFfjp {\n  display: flex;\n  align-items: center;\n  gap: 3px;\n}\n\n.RemoteFilters-module__Goj6Fnossnkos9z2aUTg .RemoteFilters-module__AG_QQYOUKKBoBquzVQMH {\n  width: 250px;\n}\n\n.RemoteFilters-module__Goj6Fnossnkos9z2aUTg .RemoteFilters-module__LAScFUoa3G0Dqe3eKZy5 {\n  min-width: 250px;\n  width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./containers/RemoteFilters/RemoteFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.filters {\n  display: flex;\n  gap: 10px;\n  padding: 10px;\n  border: var(--border);\n  border-radius: 2px;\n  flex-wrap: wrap;\n}\n\n.filter {\n  display: flex;\n  align-items: center;\n  gap: 3px;\n}\n\n.root .filter-options {\n  width: 250px;\n}\n\n.root .filter-select {\n  min-width: 250px;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "RemoteFilters-module__Goj6Fnossnkos9z2aUTg",
	"filters": "RemoteFilters-module__lfUhdeqSgBLwAacjmOZV",
	"filter": "RemoteFilters-module__t7puoZgp9lSZbC4TFfjp",
	"filter-options": "RemoteFilters-module__AG_QQYOUKKBoBquzVQMH",
	"filter-select": "RemoteFilters-module__LAScFUoa3G0Dqe3eKZy5"
};
export default ___CSS_LOADER_EXPORT___;
