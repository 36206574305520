// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhooks-module__NDKBNbxhLY0uIdo2qYwe{display:flex;align-items:center;width:100%;padding-top:12px}.OutgoingWebhooks-module__RfbWJrjX643S2Jho9V4h{display:flex;align-items:baseline}.OutgoingWebhooks-module__cJYiUCrpq0IXGmZOY1X5{display:flex;flex-direction:column;width:225px;border-radius:2px}.OutgoingWebhooks-module__aBcrTudVbaXqdYfrqvll{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.OutgoingWebhooks-module__aBcrTudVbaXqdYfrqvll:hover{background:var(--cards-background)}", "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks/OutgoingWebhooks.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CAGF,+CACE,YAAA,CACA,oBAAA,CAGF,+CACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,+CACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,qDACE,kCAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding-top: 12px;\n}\n\n.header__title {\n  display: flex;\n  align-items: baseline;\n}\n\n.hamburgerMenu {\n  display: flex;\n  flex-direction: column;\n  width: 225px;\n  border-radius: 2px;\n}\n\n.hamburgerMenu__item {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--cards-background);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OutgoingWebhooks-module__NDKBNbxhLY0uIdo2qYwe",
	"header__title": "OutgoingWebhooks-module__RfbWJrjX643S2Jho9V4h",
	"hamburgerMenu": "OutgoingWebhooks-module__cJYiUCrpq0IXGmZOY1X5",
	"hamburgerMenu__item": "OutgoingWebhooks-module__aBcrTudVbaXqdYfrqvll"
};
export default ___CSS_LOADER_EXPORT___;
