// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaintenanceForm-module__LL1vkBlVcUJgtan8hH0w {\n  display: block;\n}\n\n.MaintenanceForm-module__jB4wWm16HgMr9syocrIQ {\n  margin: 16px 0 0 16px;\n}\n\n.MaintenanceForm-module__h0DSTAtH4u9qjxe1Xpdo {\n  margin: 4px 4px 400px 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/MaintenanceForm/MaintenanceForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px 4px 400px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MaintenanceForm-module__LL1vkBlVcUJgtan8hH0w",
	"title": "MaintenanceForm-module__jB4wWm16HgMr9syocrIQ",
	"content": "MaintenanceForm-module__h0DSTAtH4u9qjxe1Xpdo"
};
export default ___CSS_LOADER_EXPORT___;
