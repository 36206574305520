// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationHeartbeatForm-module__YCUW_mnppUlT6Qc0O2_w ol,.IntegrationHeartbeatForm-module__YCUW_mnppUlT6Qc0O2_w ul{padding:0;margin:0;list-style:none}", "",{"version":3,"sources":["webpack://./containers/IntegrationContainers/IntegrationHeartbeatForm/IntegrationHeartbeatForm.module.scss"],"names":[],"mappings":"AACE,oHAEE,SAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".instruction {\n  ol,\n  ul {\n    padding: 0;\n    margin: 0;\n    list-style: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instruction": "IntegrationHeartbeatForm-module__YCUW_mnppUlT6Qc0O2_w"
};
export default ___CSS_LOADER_EXPORT___;
