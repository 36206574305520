// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UsersFilters-module__zOihEq3N_67gtE2ybvEA {\n  display: inline-flex;\n  align-items: center;\n}\n\n.UsersFilters-module__zOihEq3N_67gtE2ybvEA .UsersFilters-module__Sske5ztRv0RUKBVgtCCK {\n  width: 300px;\n}\n\n.UsersFilters-module__zOihEq3N_67gtE2ybvEA .UsersFilters-module__Sske5ztRv0RUKBVgtCCK:focus {\n  width: 500px;\n}\n\n.UsersFilters-module__qial5G7gAiwnzR1MdCdu:not(:last-child) {\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://./components/UsersFilters/UsersFilters.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n}\n\n.root .search {\n  width: 300px;\n}\n\n.root .search:focus {\n  width: 500px;\n}\n\n.control:not(:last-child) {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "UsersFilters-module__zOihEq3N_67gtE2ybvEA",
	"search": "UsersFilters-module__Sske5ztRv0RUKBVgtCCK",
	"control": "UsersFilters-module__qial5G7gAiwnzR1MdCdu"
};
export default ___CSS_LOADER_EXPORT___;
