// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar-module__x1HBWy1kgvNwDSXvBqWQ {\n  display: inline-block;\n  border-radius: 50%;\n  overflow: hidden;\n}\n\n.Avatar-module__UduL1v40NTdWqmoH07Tk {\n  width: 12px;\n  height: 12px;\n}\n\n.Avatar-module___YQzEiUgtPrOKr4I4l0s {\n  width: 16px;\n  height: 16px;\n}\n\n.Avatar-module__NrhZT3ekMBhn8Po7IQ5M {\n  width: 24px;\n  height: 24px;\n}\n\n.Avatar-module__TvDMHjZXgaH62RnjkJ9M {\n  width: 32px;\n  height: 32px;\n}\n", "",{"version":3,"sources":["webpack://./components/Avatar/Avatar.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  display: inline-block;\n  border-radius: 50%;\n  overflow: hidden;\n}\n\n.avatarSize-xs {\n  width: 12px;\n  height: 12px;\n}\n\n.avatarSize-small {\n  width: 16px;\n  height: 16px;\n}\n\n.avatarSize-medium {\n  width: 24px;\n  height: 24px;\n}\n\n.avatarSize-large {\n  width: 32px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Avatar-module__x1HBWy1kgvNwDSXvBqWQ",
	"avatarSize-xs": "Avatar-module__UduL1v40NTdWqmoH07Tk",
	"avatarSize-small": "Avatar-module___YQzEiUgtPrOKr4I4l0s",
	"avatarSize-medium": "Avatar-module__NrhZT3ekMBhn8Po7IQ5M",
	"avatarSize-large": "Avatar-module__TvDMHjZXgaH62RnjkJ9M"
};
export default ___CSS_LOADER_EXPORT___;
