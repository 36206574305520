// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlackTab-module__LyvDH3_885BJi9rX7BTC {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.SlackTab-module__A2dzf1zmykr15_42BKBr {\n  text-align: center;\n  width: 725px;\n}\n\n.SlackTab-module__dHZK4AOHq71gcisynRsb {\n  margin-right: 4px;\n  align-self: baseline;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/SlackTab/SlackTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.slack-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.external-link-style {\n  margin-right: 4px;\n  align-self: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "SlackTab-module__LyvDH3_885BJi9rX7BTC",
	"slack-infoblock": "SlackTab-module__A2dzf1zmykr15_42BKBr",
	"external-link-style": "SlackTab-module__dHZK4AOHq71gcisynRsb"
};
export default ___CSS_LOADER_EXPORT___;
