// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationPolicy-module__UBWh668xgWXMI1004ZZd {\n  z-index: 1062;\n}\n\n.NotificationPolicy-module__dOyurgxnP2JYARhmOSgY {\n  display: flex;\n  align-items: center;\n}\n\n.NotificationPolicy-module__UBWh668xgWXMI1004ZZd .NotificationPolicy-module__TG2cjzAWASf6k57qrpbk {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n\n.NotificationPolicy-module__dOyurgxnP2JYARhmOSgY .NotificationPolicy-module___HDK6yLBiaEVippXQCXd {\n  width: 200px !important;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./components/Policy/NotificationPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB","sourcesContent":[".root {\n  z-index: 1062;\n}\n\n.step {\n  display: flex;\n  align-items: center;\n}\n\n.root .control {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n\n.step .select {\n  width: 200px !important;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NotificationPolicy-module__UBWh668xgWXMI1004ZZd",
	"step": "NotificationPolicy-module__dOyurgxnP2JYARhmOSgY",
	"control": "NotificationPolicy-module__TG2cjzAWASf6k57qrpbk",
	"select": "NotificationPolicy-module___HDK6yLBiaEVippXQCXd"
};
export default ___CSS_LOADER_EXPORT___;
