// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainSettings-module__vqnPb9LWW_lypC71clvg {\n  margin-bottom: 20px;\n}\n\n.MainSettings-module__rlDhxij7rnjCrHsXOtbh {\n  width: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./pages/settings/tabs/MainSettings/MainSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.settings {\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "MainSettings-module__vqnPb9LWW_lypC71clvg",
	"settings": "MainSettings-module__rlDhxij7rnjCrHsXOtbh"
};
export default ___CSS_LOADER_EXPORT___;
