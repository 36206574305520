// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__IxlwsqXqJjSotn9h6UoU {\n  display: flex;\n  flex-direction: column;\n}\n\n.index-module__f6sN6lOsP3Wq70VIGygw {\n  margin-top: 20px;\n}\n\n.index-module__HHOWsCz8UfeN7l3XIbM6 {\n  display: flex;\n  flex-direction: row;\n}\n\n.index-module__Eu4fqOYQT4JSHx4Gypty {\n  display: flex;\n  flex-direction: column;\n}\n\n.index-module__IxW_fDhoS2BNdFgKyKlQ {\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.index-module__D27ANG3HhheDvvJmAhH4 {\n  text-align: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  margin-top: 20px;\n}\n\n.columns {\n  display: flex;\n  flex-direction: row;\n}\n\n.col {\n  display: flex;\n  flex-direction: column;\n}\n\n.right {\n  margin-left: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.right_centered {\n  text-align: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "index-module__IxlwsqXqJjSotn9h6UoU",
	"content": "index-module__f6sN6lOsP3Wq70VIGygw",
	"columns": "index-module__HHOWsCz8UfeN7l3XIbM6",
	"col": "index-module__Eu4fqOYQT4JSHx4Gypty",
	"right": "index-module__IxW_fDhoS2BNdFgKyKlQ",
	"right_centered": "index-module__D27ANG3HhheDvvJmAhH4"
};
export default ___CSS_LOADER_EXPORT___;
