// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardButton-module__D1BoTPk3n_UjonMPj6hq {\n  height: 88px;\n  position: relative;\n  cursor: pointer;\n}\n\n.CardButton-module__HNseezrRPb6Q_C1AZBCQ {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: var(--disabled-text-color);\n}\n\n.CardButton-module__ltsAgjGBqqRRVQkRJAGH {\n  position: absolute;\n  top: 14px;\n  left: 90px;\n}\n\n.CardButton-module__kOVlq30YnJUNlKKtI8Eg::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n", "",{"version":3,"sources":["webpack://./components/CardButton/CardButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,UAAU;EACV,gEAAgE;AAClE","sourcesContent":[".root {\n  height: 88px;\n  position: relative;\n  cursor: pointer;\n}\n\n.icon {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: var(--disabled-text-color);\n}\n\n.meta {\n  position: absolute;\n  top: 14px;\n  left: 90px;\n}\n\n.root_selected::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CardButton-module__D1BoTPk3n_UjonMPj6hq",
	"icon": "CardButton-module__HNseezrRPb6Q_C1AZBCQ",
	"meta": "CardButton-module__ltsAgjGBqqRRVQkRJAGH",
	"root_selected": "CardButton-module__kOVlq30YnJUNlKKtI8Eg"
};
export default ___CSS_LOADER_EXPORT___;
