// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserSettings-module__UjgHox0MIEke4UpB2nA9 {\n  width: 860px; /* wide enough so that all tabs fit in */\n}\n\n.UserSettings-module__UjgHox0MIEke4UpB2nA9.UserSettings-module__S8l7YXjxla_NolPqFqer {\n  --spacing: 10px;\n\n  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */\n  max-width: 1100px;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/UserSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,eAAe;;EAEf,sCAAsC,EAAE,0BAA0B;EAClE,iBAAiB;AACnB","sourcesContent":[".modal {\n  width: 860px; /* wide enough so that all tabs fit in */\n}\n\n.modal.modal-wide {\n  --spacing: 10px;\n\n  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */\n  max-width: 1100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "UserSettings-module__UjgHox0MIEke4UpB2nA9",
	"modal-wide": "UserSettings-module__S8l7YXjxla_NolPqFqer"
};
export default ___CSS_LOADER_EXPORT___;
