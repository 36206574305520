// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownloadIcons-module__VZ1VD1eUuoiqJkuA6Zel{width:25px;height:auto;margin-right:12px}.DownloadIcons-module__fclrh6y61ve2hsBhv3hf,.DownloadIcons-module__VZ1VD1eUuoiqJkuA6Zel{cursor:default}.DownloadIcons-module__iTC8A_voZv7V2uXc7rBi{display:flex;align-items:center;min-height:80px;column-gap:12px}.DownloadIcons-module__MbDIPKwVr9o6d7NVBF_A{border-radius:12px;font-size:12px;padding:2px 8px;cursor:default}", "",{"version":3,"sources":["webpack://./containers/MobileAppConnection/parts/DownloadIcons/DownloadIcons.module.scss"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,wFAEE,cAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".icon {\n  width: 25px;\n  height: auto;\n  margin-right: 12px;\n}\n\n.icon-text,\n.icon {\n  cursor: default;\n}\n\n.icon-block {\n  display: flex;\n  align-items: center;\n  min-height: 80px;\n  column-gap: 12px;\n}\n\n.icon-tag {\n  border-radius: 12px;\n  font-size: 12px;\n  padding: 2px 8px;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "DownloadIcons-module__VZ1VD1eUuoiqJkuA6Zel",
	"icon-text": "DownloadIcons-module__fclrh6y61ve2hsBhv3hf",
	"icon-block": "DownloadIcons-module__iTC8A_voZv7V2uXc7rBi",
	"icon-tag": "DownloadIcons-module__MbDIPKwVr9o6d7NVBF_A"
};
export default ___CSS_LOADER_EXPORT___;
