// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GList-module__yg7qSOK7cxiYeiXSUO98 {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.GList-module__cdYNC3XsForjC6qfRhj5 {\n  padding: 16px;\n  width: 100%;\n  border-bottom: var(--border);\n  position: relative;\n  cursor: pointer;\n}\n\n.GList-module__cdYNC3XsForjC6qfRhj5:first-child {\n  border-top: var(--border);\n}\n\n.GList-module__cdYNC3XsForjC6qfRhj5:hover {\n  background: var(--secondary-background);\n}\n\n.GList-module__IIKsKXkhrUb1veFNQl4S {\n  cursor: default;\n  background: var(--secondary-background);\n}\n\n.GList-module__IIKsKXkhrUb1veFNQl4S::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n", "",{"version":3,"sources":["webpack://./components/GList/GList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,UAAU;EACV,gEAAgE;AAClE","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.item {\n  padding: 16px;\n  width: 100%;\n  border-bottom: var(--border);\n  position: relative;\n  cursor: pointer;\n}\n\n.item:first-child {\n  border-top: var(--border);\n}\n\n.item:hover {\n  background: var(--secondary-background);\n}\n\n.item_selected {\n  cursor: default;\n  background: var(--secondary-background);\n}\n\n.item_selected::before {\n  display: block;\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 4px;\n  background-image: linear-gradient(270deg, #f55f3e 0%, #f83 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "GList-module__yg7qSOK7cxiYeiXSUO98",
	"item": "GList-module__cdYNC3XsForjC6qfRhj5",
	"item_selected": "GList-module__IIKsKXkhrUb1veFNQl4S"
};
export default ___CSS_LOADER_EXPORT___;
