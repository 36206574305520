// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationTemplateBlock-module__U1xHIJ7nuXMBITKGfIwq{display:flex;flex-direction:row;gap:4px}.IntegrationTemplateBlock-module__C0ogHi4vDQqoxf7SrwOq{flex-grow:1;white-space:nowrap;overflow:hidden;display:flex;flex-direction:row;gap:4px}.IntegrationTemplateBlock-module__U1xHIJ7nuXMBITKGfIwq label{margin-right:0px}", "",{"version":3,"sources":["webpack://./components/Integrations/IntegrationTemplateBlock.module.scss"],"names":[],"mappings":"AAAA,uDACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,uDACE,WAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,6DACE,gBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n\n  &__item {\n    flex-grow: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n  }\n\n  label {\n    margin-right: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IntegrationTemplateBlock-module__U1xHIJ7nuXMBITKGfIwq",
	"container__item": "IntegrationTemplateBlock-module__C0ogHi4vDQqoxf7SrwOq"
};
export default ___CSS_LOADER_EXPORT___;
