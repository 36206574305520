// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneVerification-module__O9rOlezk5uSZSWAwqx3V {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.PhoneVerification-module__XPMYU8yDlOc1NO3zjGe0 {\n  margin-right: 12px;\n}\n\n.PhoneVerification-module__KyjLr139yuIC_x8UVrHQ {\n  width: 100%;\n  margin-bottom: 8px;\n}\n\n.PhoneVerification-module__vFg3R6H66CoZjIeqSfgm {\n  display: block;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/PhoneVerification/PhoneVerification.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".switch {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.switch__icon {\n  margin-right: 12px;\n}\n\n.phone__field {\n  width: 100%;\n  margin-bottom: 8px;\n}\n\n.phone__forgetHeading {\n  display: block;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "PhoneVerification-module__O9rOlezk5uSZSWAwqx3V",
	"switch__icon": "PhoneVerification-module__XPMYU8yDlOc1NO3zjGe0",
	"phone__field": "PhoneVerification-module__KyjLr139yuIC_x8UVrHQ",
	"phone__forgetHeading": "PhoneVerification-module__vFg3R6H66CoZjIeqSfgm"
};
export default ___CSS_LOADER_EXPORT___;
