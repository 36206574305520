// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Unauthorized-module__Y8kbu7Mkae2BKA7SOoDh {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.Unauthorized-module__S5l_Il0jbQm_fEBNNdhk {\n  color: var(--warning-text-color);\n}\n", "",{"version":3,"sources":["webpack://./components/Unauthorized/Unauthorized.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".not-found {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.error-code {\n  color: var(--warning-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found": "Unauthorized-module__Y8kbu7Mkae2BKA7SOoDh",
	"error-code": "Unauthorized-module__S5l_Il0jbQm_fEBNNdhk"
};
export default ___CSS_LOADER_EXPORT___;
