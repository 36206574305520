// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PersonalNotificationSettings-module__tHhiXAGkPGSb3Oo80Ejv {\n  margin-bottom: 25px;\n}\n\n.PersonalNotificationSettings-module__tHhiXAGkPGSb3Oo80Ejv .PersonalNotificationSettings-module__lFOCCR9vcYlDqUredDCT {\n  margin: 15px 0 0 15px;\n}\n\n.PersonalNotificationSettings-module__Zyej0vO7EIUJLCQyMbnk {\n  z-index: 1062;\n}\n\n.PersonalNotificationSettings-module__tHhiXAGkPGSb3Oo80Ejv .PersonalNotificationSettings-module__pdQFAQxCwlFPKVrwVlXo {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./containers/PersonalNotificationSettings/PersonalNotificationSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".root {\n  margin-bottom: 25px;\n}\n\n.root .steps {\n  margin: 15px 0 0 15px;\n}\n\n.sortable-helper {\n  z-index: 1062;\n}\n\n.root .step {\n  display: flex;\n  align-items: center;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PersonalNotificationSettings-module__tHhiXAGkPGSb3Oo80Ejv",
	"steps": "PersonalNotificationSettings-module__lFOCCR9vcYlDqUredDCT",
	"sortable-helper": "PersonalNotificationSettings-module__Zyej0vO7EIUJLCQyMbnk",
	"step": "PersonalNotificationSettings-module__pdQFAQxCwlFPKVrwVlXo"
};
export default ___CSS_LOADER_EXPORT___;
